/* eslint-disable consistent-return */
import axios from 'axios';
import GLOBAL from '../config/global';
import encryptionHelper from '../helpers/encryptionHelper';

const headers = {
  headers: {
    'x-api-key': GLOBAL.API_AUTH_KEY,
  },
};

const getEncrypted = (data) => {
  let cipString = encryptionHelper.encrypt(data, GLOBAL.CHAT_API_SECRET);
  return cipString;
}

const Login = async ({ username, password }) => {
  try {
    console.log('do login');
    if (username && password) {
      let data = getEncrypted({
        email: username,
        password: password,
      });
      const userData = await axios.post(`${GLOBAL.API_URL}/login`,
        data,
        headers,
      );
      console.log("UserData", userData);
      if (userData) {
        const exchangeData = await axios.post(`${GLOBAL.API_URL}/getTodayExchangeRate`, {}, headers);
        window.sessionStorage.setItem('xrate', exchangeData.data.message.BWPRate);
        window.sessionStorage.setItem('UsdToZar', exchangeData.data.message.USDRateToZAR);
        window.sessionStorage.setItem('UsdToBwp', exchangeData.data.message.USDRateToBWP);
        window.sessionStorage.setItem('username', username);
        window.sessionStorage.setItem('fullName', userData.data.fullName);
        window.sessionStorage.setItem('paymentToken', userData.data.paymentToken);
        window.sessionStorage.setItem('authToken', userData.data.idToken.jwtToken);
        window.sessionStorage.setItem('billingType', userData.data.billingType);
        window.sessionStorage.setItem('role', userData.data.role);
        window.sessionStorage.setItem('companyId', userData.data.companyId);
        window.sessionStorage.setItem('authState', 'LOGGED_IN');
        window.sessionStorage.setItem('allData', false);
        if (userData.data.paymentToken === '' && userData.data.billingType === 'CC') {
          window.location.href = '/paymentForm';
        } else {
          window.location.href = '/search';
        }
      }
      return true;
    }
  } catch (e) {
    return e.message;
  }
};

const Api = {
  Login,
};

export default Api;
