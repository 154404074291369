var CryptoJS = require('node-cryptojs-aes').CryptoJS;

module.exports.encrypt = (object, secret) => {
  var cip = "";
  let options = {
    keySize: 128 / 8,
    iv: secret,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }
  if (typeof object === "object") {
    cip = CryptoJS.AES.encrypt(JSON.stringify(object), secret, options).toString();
  } else {
    cip = CryptoJS.AES.encrypt(object, secret, options).toString();    
  }
  return cip
}

module.exports.decrypt = (string, secret) => {
  let object = {};
  let options = {
    keySize: 128 / 8,
    iv: secret,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }
  var decrypted = CryptoJS.AES.decrypt(string, secret, options);
  var data = CryptoJS.enc.Utf8.stringify(decrypted)
  try {
    object =  JSON.parse(data); 
  } catch (error) {
    //swallow error
    object = data;
  }
  return object
}