import * as React from "react";
import Spinner from 'react-bootstrap/Spinner';

export const MyCommandCell = props => {
  const {
    dataItem
  } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.ProductID === undefined;
  return inEdit ? <td className="k-command-cell">
    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command" onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}>
      {isNewItem ? "Add" : "Update"}
    </button>
    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command" onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)}>
      {isNewItem ? "Discard" : "Cancel"}
    </button>
  </td> : <td className="k-command-cell">
    <button disabled={!dataItem.payed && props.isBilled === 'CC'} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => props.view(dataItem)}>
      VIEW
    </button>
    <button disabled={!dataItem.payed && props.isBilled === 'CC'} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => props.report(dataItem)}>
      REPORT
    </button>
    <button hidden={dataItem.payed || props.isBilled !== 'CC'} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => props.payNow(dataItem)}>
      {props.isloading ? (
        <>
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </>
      ) : (
        <>
          PAY
        </>
      )}
    </button>
  </td>;
};