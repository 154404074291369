
import GLOBAL from '../../config/global';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const Payment = function Payment() {

  let myData = [];
  let returnArray = [];
  let payMode = 'sub'

  if (payMode !== 'sub') {

    // Merchant details
    myData["merchant_id"] = GLOBAL.merchant_id;
    myData["merchant_key"] = GLOBAL.merchant_key;
    myData["return_url"] = GLOBAL.return_url;
    myData["cancel_url"] = GLOBAL.return_url;
    myData["notify_url"] = GLOBAL.notify_url;
    // Buyer details
    myData["name_first"] = "First Name";
    myData["name_last"] = "Last Name";
    myData["email_address"] = window.sessionStorage.getItem('username');
    // Transaction details
    myData["amount"] = "150.00";
    myData["item_name"] = "AutoValSearch";
    //Subscription Details
    myData["subscription_type"] = "2";
    myData["payment_method"] = "cc";

  } else {
    //Subscription Details
    myData["subscription_type"] = "2";
    myData["payment_method"] = "cc";
    myData["recurring_amount"] = "150";
    // myData["frequency"] = "3";
    // myData["cycles"] = "12";
    myData["subscription_notify_email"] = "false";
    myData["subscription_notify_webhook"] = "true";
    myData["subscription_notify_buyer"] = "false";
    myData["email_address"] = window.sessionStorage.getItem('username');
    myData["item_name"] = "AutoValSearch";
    // Merchant details
    myData["merchant_id"] = GLOBAL.merchant_id;
    myData["merchant_key"] = GLOBAL.merchant_key;
    myData["return_url"] = GLOBAL.return_url;
    myData["cancel_url"] = GLOBAL.cancel_url;
    myData["notify_url"] = GLOBAL.notify_url;
    myData["amount"] = "0.00";
  }

  const myPassphrase = GLOBAL.PASSPHRASE;
  const PostURL = GLOBAL.PayfastURL;
  for (let key in myData) {
    if (myData.hasOwnProperty(key)) {
      let value = myData[key];
      if (value !== "") {
        returnArray.push(<input name={key} type="hidden" value={value.trim()} />);
      }
    }
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center' }} >
          <Card>
            <Card.Header style={{ backgroundColor: 'grey' }}>
              COMPANY DETAILS
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <form action={PostURL} method="post">
                    {returnArray}
                    {/* <input type="submit" value="SET CREDIT CARD DETAILS" /> */}
                    <Button variant='flat' type="submit">SET CREDIT CARD DETAILS</Button>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

};

export default Payment;