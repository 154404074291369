/* eslint-disable consistent-return */
import axios from 'axios';
import GLOBAL from '../config/global';
import encryptionHelper from '../helpers/encryptionHelper';

const headers = {
  headers: {
    'x-api-key': GLOBAL.API_AUTH_KEY,
  },
};

const getEncrypted = (data) => {
  let cipString = encryptionHelper.encrypt(data, GLOBAL.CHAT_API_SECRET);
  return cipString;
}

const getUsers = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getUsers`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const Api = {
  getUsers,
};

export default Api;
