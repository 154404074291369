/* eslint-disable consistent-return */
import axios from 'axios';
import GLOBAL from '../config/global';
import encryptionHelper from '../helpers/encryptionHelper';

const headers = {
  headers: {
    'x-api-key': GLOBAL.API_AUTH_KEY,
  },
};

const valuationHeaders = {
  headers: {
    'x-api-key': '1e1e19bb-4c80-4c99-b38a-ba71c045d3e8',
  },
};

const getEncrypted = (data) => {
  let cipString = encryptionHelper.encrypt(data, GLOBAL.CHAT_API_SECRET);
  return cipString;
}

const getSearches = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getSearches`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const evaluateVehicle = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/valuateVehicle`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const doSpecLookup = async (filter) => {
  let data = getEncrypted(filter);
  try {
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doSpecLookup`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const doChassisLookup = async (filter) => {
  let data = getEncrypted(filter);
  try {
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doChassisLookup`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const doVehicleValuation = async (filter) => {
  let data = getEncrypted(filter);
  try {
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doVehicleValuation`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const doVehicleTrimLookup = async (filter) => {
  let data = getEncrypted(filter);
  try {
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doVehicleTrimLookup`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const doVehicleOptionLookup = async (filter) => {
  let data = getEncrypted(filter);
  try {
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doVehicleOptionLookup`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const doLightstoneLookup = async (filter) => {
  try {
    let data = getEncrypted(filter);
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/doLightstoneLookup`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "LightstoneError"
    }
    return error;
  }
};

const checkVinExists = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/checkVinExists`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const getTransResults = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getTransResults`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const getGreyImport = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getGreyImportVehicle`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const getVehicleByVin = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getVehicleByVin`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const getVehicleValuation = async (data) => {
  const rtnObj = await axios.post(
    `${GLOBAL.VALUATION_API_URL}/getVehicleValuation`,
    data,
    valuationHeaders,
  );
  return rtnObj.data.message;
};

const getMakeModelIds = async (filter) => {
  try {
    let data = getEncrypted(filter);
    const rtnObj = await axios.post(
      `${GLOBAL.API_URL}/getMakeModelIds`,
      data,
      headers,
    );
    return rtnObj.data.message;
  } catch (e) {
    let error = {
      message: "No Vehicle Match"
    }
    return error;
  }
};

const Api = {
  getMakeModelIds,
  getVehicleValuation,
  doVehicleOptionLookup,
  doVehicleTrimLookup,
  doChassisLookup,
  getVehicleByVin,
  getGreyImport,
  doVehicleValuation,
  doSpecLookup,
  getTransResults,
  doLightstoneLookup,
  checkVinExists,
  getSearches,
  evaluateVehicle,
};

export default Api;
