const global = {
  API_URL: 'https://d7k5s1xbc1.execute-api.eu-west-1.amazonaws.com/prod/',
  VALUATION_API_URL: 'https://pdcbq54prl.execute-api.eu-west-1.amazonaws.com/prod/',
  BaseURL: 'https://autoval.link/',
  merchant_id: '21472452',
  merchant_key: '3fd1meohcxscx',
  PayfastURL: 'https://www.payfast.co.za/eng/process',
  PASSPHRASE: 'DashDashInTheHashCash123',
  notify_url: 'https://d7k5s1xbc1.execute-api.eu-west-1.amazonaws.com/prod/confirmPayment',
  return_url: 'https://autoval.link/search',
  cancel_url: 'https://autoval.link/PaymentForm',
  CHAT_API_SECRET: 'As an AI language model',
  CHAT_API_KEY: "f519b3a3-6144-49d0-a327-c5441bab3827",
  API_AUTH_KEY: "750e4589-e9ca-44c4-b8cd-a0ad153b9327"
};

// API_URL: 'https://d7k5s1xbc1.execute-api.eu-west-1.amazonaws.com/prod/',
// API_URL: 'http://localhost:5000/prod',
export default global;
