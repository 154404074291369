import React from 'react';
import ReactToPrint from 'react-to-print';
import DataComponent from './DataComponent';
import { Button } from 'react-bootstrap';

class PdfComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      selectedData,
      transResultsFound,
      greyImportObject,
      companyObject,
      currentExchangeRate,
      currentExchangeRateObject,
    } = this.props;
    return (
      <div>
        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => <Button variant="flat" >Print to PDF</Button>}
        />
        <DataComponent ref={(response) => (this.componentRef = response)} currentExchangeRateObject={currentExchangeRateObject} greyImportObject={greyImportObject} selectedData={selectedData} transResultsFound={transResultsFound} companyObject={companyObject} currentExchangeRate={currentExchangeRate} />
      </div>
    );
  }

}

export default PdfComponent;