/* eslint-disable consistent-return */
import axios from 'axios';
import GLOBAL from '../config/global';
import encryptionHelper from '../helpers/encryptionHelper';

const headers = {
  headers: {
    'x-api-key': GLOBAL.API_AUTH_KEY,
  },
};

const getEncrypted = (data) => {
  let cipString = encryptionHelper.encrypt(data, GLOBAL.CHAT_API_SECRET);
  return cipString;
}

const getMakeModels = async (makeId) => {
  try {
    if (makeId) {
      let data = getEncrypted(makeId);
      const rtnObj = await axios.post(`${GLOBAL.API_URL}/getMakeModels`, data, headers);
      return  rtnObj.data.message;
    }
  } catch (e) {
    return e.message;
  }
};

const Api = {
  getMakeModels,
};

export default Api;
