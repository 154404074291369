import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';

//Adapters
import userAdapter from '../../adapters/userAdapter'
import companyAdapter from '../../adapters/companyAdapter'

//CSS
import './admin.css'

const Admin = function Admin() {
  const [validated, setValidated] = useState(false);
  const [isloggedIn, setIsLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [show, setShow] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [users, setUsers] = useState([]);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    checkAuth();
    fetchData();
  }, [setCompanies]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkAuth = () => {
    if (window.sessionStorage.getItem('authState') !== 'LOGGED_IN') {
      window.location.href = './login'
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    let filter = { companyId: "all" }
    let foundCompanies = await companyAdapter.getCompanies(filter);
    let foundUsers = await userAdapter.getUsers(filter);
    setCompanies(foundCompanies);
    setUsers(foundUsers);
    setIsLoading(false);
  }

  const setShowModal = (modalType) => {
    setModalState(modalType);
    handleShow();
  }

  const submitModalDetails = async () => {
    if (modalState === 'user') {

    } else if (modalState === 'company') {
      let createdCompany = await companyAdapter.addCompany({ companyName: companyName })
    } else if (modalState === 'user update') {

    } else {

    }
    fetchData();
    handleClose();
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <br />
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h3>USERS</h3>
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
            <Col>
              <Button variant="flat" onClick={() => setShowModal('user')}>ADD</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className='input-table'>
            <Table striped hover variant="light">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Company ID</th>
                  <th>Create Date</th>
                  <th>Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!isLoading ? (
                  users.map(user =>
                  (
                    <tr>
                      <td>{user.fullName}</td>
                      <td>{user.email}</td>
                      <td>{user.companyId}</td>
                      <td>{user.createDate}</td>
                      <td>{user.active ? "YES" : "NO"}</td>
                      <td><Button variant="flat" onClick={() => setShowModal('user update')}>UPDATE</Button></td>
                    </tr>
                  ))
                ) : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h3>COMPANIES</h3>
            </Col>
            <Col>
            </Col>
            <Col>
              <Button variant="flat" onClick={() => setShowModal('company')}>ADD</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className='input-table'>
            <Table striped hover variant="light">
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Create Date</th>
                  <th>Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!isLoading ? (
                  companies.map(comapny =>
                  (
                    <tr>
                      <td>{comapny.companyName}</td>
                      <td>{comapny.createDate}</td>
                      <td>{comapny.active ? "YES" : "NO"}</td>
                      <td><Button variant="flat" onClick={() => setShowModal('company update')}>UPDATE</Button></td>
                    </tr>
                  ))
                ) : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalState === 'user' ? "ADD USER" : modalState === 'company' ? "ADD COMPANY" : modalState === 'user update' ? "UPDATE USER" : "UPDATE COMPANY"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalState === 'user' ? (
            <Card>
              <Card.Body>
                <Table striped hover variant="light">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Full name </td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>
                        <input type='text'></input>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : modalState === 'company' ? (
            <>
              Company Name <input type='text' onChange={(e) => setCompanyName(e.target.value)}></input><br />
            </>
          ) : modalState === 'user update' ? (
            <Card>
              <Card.Body>
                <Table striped hover variant="light">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Full name </td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>
                        <input type='text'></input>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <Table striped hover variant="light">
                  <tbody>
                    <tr>
                      <td>Company Name </td>
                      <td>
                        <input type='text'></input><br />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="flat" onClick={submitModalDetails}>
            SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Admin;