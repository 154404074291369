import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';

//adapters
import loginAdapter from '../../adapters/loginAdaptor'

//styling
import './login.css'
import 'react-toastify/dist/ReactToastify.css';

const Login = function Login() {
  const [validated, setValidated] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      //DO LOGIN
      let loginResult = await loginAdapter.Login({ username: userName, password: password });
      if(loginResult !== true){
        toast.error('Invalid username or password');
        setShowForgotPass(true);
      }
    }
    setIsSubmitting(false);
    setValidated(true);
  };

  return (
    <Container >
      <br />
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <img
            alt=""
            src="/AutoVal.png"
            width="150"
            height="150"
            className="d-inline-block align-top"
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ width: '50rem' }}>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3"
                >

                  <Form.Control type="email" placeholder="name@example.com" required onChange={(e) => setUserName(e.target.value)} />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control type="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
                </FloatingLabel>
                {showForgotPass? (
                  <div onClick={handleShow}>Forgot your password ?</div>
                ) : null}
                <br/>
                <Button variant="flat" type="submit">
                  {isSubmitting ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <>
                      Submit
                    </>
                  )}
                </Button>
              </Form>
            </Card.Body>
            <Card.Footer>
              When you log in you agree to our TERMS and CONDITIONS
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>FORGOT PASSWORD ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To have your password reset kindly contact your supplier.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Login;