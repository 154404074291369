import React from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import LSLogo from './LSLogo.png'
import TULogo from './TULogo.png'
import ASLogo from './ASLogo.png'

class DataComponent extends React.Component {
  render() {
    const {
      selectedData,
      transResultsFound,
      companyObject,
      currentExchangeRate,
      greyImportObject,
      currentExchangeRateObject,
    } = this.props;

    var d1 = new Date()
    var str = d1.toISOString().slice(0, -5)

    console.log(selectedData);

    let useLightStoneData = false;
    let vehicleData = {};
    let mpiData = {};
    let lighstoneDataObject = {};
    let lighstoneDataArr = [];
    let VehicleCodeAndDescptionInfo = {};
    let isGreyImport = selectedData.isGrey;
    let valuationDetails = {};
    let companyImportDetails = {};
    let freightAndLandingCost = 0;
    let extraOptions = [];
    let extraOptionsValue = 0;

    if (isGreyImport === true) {
      valuationDetails = greyImportObject.valuationDetails;
      if(greyImportObject.selectedExtras !== undefined){
        extraOptions = greyImportObject.selectedExtras;
        extraOptionsValue = extraOptions.length * 5000;
      }
      companyImportDetails = companyObject.companyImportDetails;

      freightAndLandingCost = (companyImportDetails.insuranceBond + (companyImportDetails.seaFreight * currentExchangeRateObject.UsdToBwp) + (companyImportDetails.roadFreight * currentExchangeRate)).toFixed();
    }


    if (transResultsFound !== undefined && transResultsFound !== null) {
      if (transResultsFound["lighstoneResponse"]) {
        useLightStoneData = true;
        lighstoneDataObject = JSON.parse(transResultsFound["lighstoneResponse"]);
        lighstoneDataArr = lighstoneDataObject["lightStoneDataArr"];
      }
      else {
        vehicleData = JSON.parse(transResultsFound["convergedData"]);
        VehicleCodeAndDescptionInfo = vehicleData["d4p1:VehicleCodeAndDescptionInfo"]["d4p1:VehicleCodeAndDescription"];

        if (transResultsFound["mpiResponse"] !== undefined) {
          mpiData = JSON.parse(transResultsFound["mpiResponse"]);
        }

        for (const key in VehicleCodeAndDescptionInfo) {
          if (typeof VehicleCodeAndDescptionInfo[key] === "object") {
            VehicleCodeAndDescptionInfo[key] = "Not Available";
          }
        }
      }

    } else {

    }


    let imageData = companyObject.companyLogo;
    let reportColors = companyObject.companyColors;

    let recomendedValue = ((Number(valuationDetails.meanValue) + Number(valuationDetails.medianValue) + Number(valuationDetails.lowValue)) / 3);

    return (
      <div className="pdf-background-export">
        <br />
        <br />
        <div style={{ width: '55rem', marginLeft: "10rem" }}>
          <img
            alt=""
            src={imageData}
            width="400"
            height="150"

            className="d-inline-block align-top"
          />
        </div>
        <br />
        <br />
        <br />
        <Card style={{ width: '55rem', marginBottom: '2rem', marginLeft: "10rem" }}>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: '20rem' }}>COMPANY DETAILS :</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>Name</b></td>
                  <td>{companyObject.companyName}</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>{companyObject.companyEmail}</td>
                </tr>
                <tr>
                  <td><b>Tel</b></td>
                  <td>{companyObject.companyTell}</td>
                </tr>
                <tr>
                  <td><b>Address</b></td>
                  <td>{companyObject.companyAddress}</td>
                </tr>
                <tr>
                  <td><b>Date Requested</b></td>
                  <td>{str}</td>
                </tr>
                <tr>
                  <td><b>AutoVal Ref No</b></td>
                  <td>{selectedData.searchId}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {isGreyImport === true ? (
          <Card style={{ width: '55rem', marginBottom: '2rem', marginLeft: "10rem" }}>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th style={{ width: '20rem' }}>COUNTRY IMPORT COSTING DETAILS :</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Current Exchange Rate ZAR to P</b></td>
                    <td>{currentExchangeRate} BWP to the Rand.</td>
                  </tr>
                  <tr>
                    <td><b>Current Exchange Rate USD to P</b></td>
                    <td>{currentExchangeRateObject.UsdToBwp} USD to the BWP.</td>
                  </tr>
                  <tr>
                    <td><b>Current Exchange Rate USD to ZAR</b></td>
                    <td>{currentExchangeRateObject.UsdToZar} USD to the Rand.</td>
                  </tr>
                  {/* <tr>
                    <td><b>Duties</b></td>
                    <td>{companyImportDetails.regionDuties} %</td>
                  </tr>
                  <tr>
                    <td><b>VAT</b></td>
                    <td>{companyImportDetails.regionVat} %</td>
                  </tr>
                  <tr>
                    <td><b>Insurance</b></td>
                    <td>{companyImportDetails.insuranceBond} BWP</td>
                  </tr>
                  <tr>
                    <td><b>Sea Freight (JPN/ZA)</b></td>
                    <td>{companyImportDetails.seaFreight} USD</td>
                  </tr>
                  <tr>
                    <td><b>Road Freight (ZA/BW)</b></td>
                    <td>{companyImportDetails.roadFreight} ZAR</td>
                  </tr>
                  <tr>
                    <td><b>Road Freight (ZA/BW)</b></td>
                    <td>{companyImportDetails.dealerMargin} %</td>
                  </tr> */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : null}
        <br />
        <Card style={{ width: '55rem', marginBottom: '2rem', marginLeft: "10rem" }}>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th><b>SUPPLIED INFORMATION :</b></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>Registration Number</b></td>
                  <td>{selectedData.vehicleReg}</td>
                </tr>
                <tr>
                  <td><b>VIN Number</b></td>
                  <td>{selectedData.vehicleVin}</td>
                </tr>
                <tr>
                  <td><b>{isGreyImport ? "Vehicle Model Code" : "Vehicle (M&M) Code"}</b></td>
                  <td>{isGreyImport ? greyImportObject.vehicleModelCode : selectedData.vehicleCode}</td>
                </tr>
                <tr>
                  <td><b>Vehicle Year</b></td>
                  <td>{selectedData.vehicleYear}</td>
                </tr>
                <tr>
                  <td><b>Vehicle Mileage</b></td>
                  <td>{selectedData.vehicleMileage}</td>
                </tr>
                <tr>
                  <td><b>Vehicle Condition</b></td>
                  <td>{selectedData.vehicleCondition ? selectedData.vehicleCondition === "E" ? "Excellent" : selectedData.vehicleCondition === "VG" ? "Very Good" : selectedData.vehicleCondition === "G" ? "Good" : selectedData.vehicleCondition === "P" ? "Poor" : selectedData.vehicleCondition === "VP" ? "Very Poor" : "" : "NOT SUPPLIED"}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {extraOptions.length > 0 ? (
          <Card style={{ width: '55rem', marginBottom: '30rem', marginLeft: "10rem" }}>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th><b>SELECTED EXTRAS</b></th>
                </tr>
              </thead>
              <tbody>
                {extraOptions.map(option => (
                  <tr>
                  <td><b>{option}</b></td>
                </tr>
                ))}                
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        ) : null}        
        <br />
        <Card style={{ width: '55rem', marginLeft: "10rem" }}>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>VEHICLE DETAILS :</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {greyImportObject.vehicleMake || VehicleCodeAndDescptionInfo["d4p1:VehicleMake"] ? (
                  <tr>
                    <td><b>Vehicle Make</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleMake : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Make").Value : VehicleCodeAndDescptionInfo["d4p1:VehicleMake"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleModel || VehicleCodeAndDescptionInfo["d4p1:VehicleModel"] ? (
                  <tr>
                    <td><b>Model Range</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleModel : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Type").Value : VehicleCodeAndDescptionInfo["d4p1:VehicleModel"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleIntroductionDate || VehicleCodeAndDescptionInfo["d4p1:IntroductionDate"] ? (
                  <tr>
                    <td><b>Introduction Date</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleIntroductionDate : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Introduction Date").Value : VehicleCodeAndDescptionInfo["d4p1:IntroductionDate"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleCylinders || VehicleCodeAndDescptionInfo["d4p1:Cylinders"] ? (
                  <tr>
                    <td><b>Cylinders</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleCylinders : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Cylinders").Value : VehicleCodeAndDescptionInfo["d4p1:Cylinders"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleFuel || VehicleCodeAndDescptionInfo["d4p1:FuelType"] ? (
                  <tr>
                    <td><b>Fuel Type</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleFuel : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Fuel type").Value : VehicleCodeAndDescptionInfo["d4p1:FuelType"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleDrive || VehicleCodeAndDescptionInfo["d4p1:Drive"] ? (
                  <tr>
                    <td><b>Drive</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleDrive : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Drive type").Value : VehicleCodeAndDescptionInfo["d4p1:Drive"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleTransmission || VehicleCodeAndDescptionInfo["d4p1:ManualAuto"] ? (
                  <tr>
                    <td><b>Manual Auto</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleTransmission : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Transmission Type").Value : VehicleCodeAndDescptionInfo["d4p1:ManualAuto"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleNoOfGears || VehicleCodeAndDescptionInfo["d4p1:NoGears"] ? (
                  <tr>
                    <td><b>No of Gears</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleNoOfGears : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Number of Gears").Value : VehicleCodeAndDescptionInfo["d4p1:NoGears"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleSeats || VehicleCodeAndDescptionInfo["d4p1:Seats"] ? (
                  <tr>
                    <td><b>Seats</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleSeats : useLightStoneData === true ? "" : VehicleCodeAndDescptionInfo["d4p1:Seats"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleDoors || VehicleCodeAndDescptionInfo["d4p1:Doors"] ? (
                  <tr>
                    <td><b>Doors</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleDoors : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Doors").Value : VehicleCodeAndDescptionInfo["d4p1:Doors"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleFuelTankSize || VehicleCodeAndDescptionInfo["d4p1:FuelTankSize"] ? (
                  <tr>
                    <td><b>Fuel Tank Size</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleFuelTankSize : useLightStoneData === true ? "" : VehicleCodeAndDescptionInfo["d4p1:FuelTankSize"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleRearTyreSize || VehicleCodeAndDescptionInfo["d4p1:RearTyreSize"] ? (
                  <tr>
                    <td><b>Rear Tyre Size</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleRearTyreSize : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Tyre Size - Rear").Value : VehicleCodeAndDescptionInfo["d4p1:RearTyreSize"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehiclePower || VehicleCodeAndDescptionInfo["d4p1:Kilowatts"] ? (
                  <tr>
                    <td><b>Kilowatts</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehiclePower : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Power (kW)").Value : VehicleCodeAndDescptionInfo["d4p1:Kilowatts"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleCC || VehicleCodeAndDescptionInfo["d4p1:CC"] ? (
                  <tr>
                    <td><b>CC</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleCC : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Engine size").Value : VehicleCodeAndDescptionInfo["d4p1:CC"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleBodyType || VehicleCodeAndDescptionInfo["d4p1:VehicleTypeDescription"] ? (
                  <tr>
                    <td><b>Vehicle Type Description</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleBodyType : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "Body shape").Value : VehicleCodeAndDescptionInfo["d4p1:VehicleTypeDescription"]}</td>
                  </tr>
                ) : null}
                {greyImportObject.vehicleGCM || VehicleCodeAndDescptionInfo["d4p1:GCM"] ? (
                  <tr>
                    <td><b>GCM</b></td>
                    <td>{isGreyImport === true ? greyImportObject.vehicleGCM : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "GCM (kg)").Value : VehicleCodeAndDescptionInfo["d4p1:GCM"]}</td>
                  </tr>
                ) : null}
                {isGreyImport !== true && VehicleCodeAndDescptionInfo["d4p1:VehicleVariant"] ? (
                  <tr>
                    <td><b>Vehicle Variant</b></td>
                    <td>{VehicleCodeAndDescptionInfo["d4p1:VehicleVariant"]}</td>
                  </tr>) : null
                }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <br />
        {isGreyImport !== true ? (
          <Card style={{ width: '55rem', marginLeft: "10rem" }}>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>{useLightStoneData === true ? "Car Id :" : "Vehicle M&M Code :"}</th>
                    <th></th>
                    <th>{selectedData.vehicleCode ? selectedData.vehicleCode : useLightStoneData === true ? lighstoneDataArr.find(item => item.Description === "CarId").Value : selectedData.vehicleCode}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td><b>New Price</b></td>
                    <td></td>
                    <td>{companyObject.companyCurrency === 'ZAR' ? (<>R {selectedData.vehicleNewPrice} </>) : (
                      <>R {((100 / 115 * selectedData.vehicleNewPrice) * 112 / 100).toFixed()} | P {((100 / 115 * selectedData.vehicleNewPrice * currentExchangeRate) * 112 / 100).toFixed()}</>)}</td>
                  </tr> */}
                  <tr>
                    <td></td>
                    <td><b>Trade</b></td>
                    <td><b>Retail</b></td>
                  </tr>
                  <tr>
                    <td><b>Adjusted</b></td>
                    <td>{companyObject.companyCurrency === 'ZAR' ? (<>R {selectedData.vehicleTradePrice} </>) : (
                      <>R {((selectedData.vehicleTradePrice)).toFixed()} | P {((0.85 * selectedData.vehicleTradePrice * currentExchangeRate) * 1.14).toFixed()}</>)}</td>
                    <td>{companyObject.companyCurrency === 'ZAR' ? (<>R {selectedData.vehicleRetailPrice} </>) : (
                      <>R {((selectedData.vehicleRetailPrice)).toFixed()} | P {((0.85 * selectedData.vehicleRetailPrice * currentExchangeRate) * 1.14).toFixed()}</>)}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <Card style={{ width: '55rem', marginLeft: "10rem" }}>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>Valuation Details (INCL VAT) :</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    {/* <td><b>RETAIL PRICE AT SOURCE</b></td> */}
                    {/* <td><b>LOCAL LANDED COST</b></td> */}
                    <td><b>LOCAL PRIVATE PRICE (INCL VAT)</b></td>
                    <td><b>LOCAL DEALER PRICE (INCL VAT)</b></td>
                  </tr>
                  <tr>
                    <td><b>Recomended Value</b></td>
                    <td>{(Number(valuationDetails.privateValue ? valuationDetails.privateValue : valuationDetails.lowValue) + extraOptionsValue )} BWP</td>
                    <td>{(Number(valuationDetails.dealerValue ? valuationDetails.dealerValue : valuationDetails.medianValue) + extraOptionsValue )} BWP</td>
                    {/* <td>{((Number(valuationDetails.lowValue) + ((Number(valuationDetails.lowValue) * companyImportDetails.regionDuties) / 100) + ((valuationDetails.lowValue * companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td> */}
                    {/* <td>{(((Number(valuationDetails.lowValue) + ((valuationDetails.lowValue * companyImportDetails.regionDuties) / 100) + ((valuationDetails.lowValue * companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td> */}
                    {/* <td>{(((((Number(valuationDetails.lowValue) + ((valuationDetails.lowValue * companyImportDetails.regionDuties) / 100) + ((valuationDetails.lowValue * companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(valuationDetails.lowValue) + ((valuationDetails.lowValue * companyImportDetails.regionDuties) / 100) + ((valuationDetails.lowValue * companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td> */}
                  </tr>
                  {/* <tr>
                    <td><b>High Value</b></td>
                    <td>{((Number(valuationDetails.highValue) + (valuationDetails.highValue * companyImportDetails.regionDuties / 100) + (valuationDetails.highValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                    <td>{(((Number(valuationDetails.highValue) + (valuationDetails.highValue * companyImportDetails.regionDuties / 100) + (valuationDetails.highValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                    <td>{(((((Number(valuationDetails.highValue) + (valuationDetails.highValue * companyImportDetails.regionDuties / 100) + (valuationDetails.highValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(valuationDetails.highValue) + (valuationDetails.highValue * companyImportDetails.regionDuties / 100) + (valuationDetails.highValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                  </tr>
                  <tr>
                    <td><b>Mean Value</b></td>
                    <td>{((Number(valuationDetails.meanValue) + (valuationDetails.meanValue * companyImportDetails.regionDuties / 100) + (valuationDetails.meanValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                    <td>{(((Number(valuationDetails.meanValue) + (valuationDetails.meanValue * companyImportDetails.regionDuties / 100) + (valuationDetails.meanValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                    <td>{(((((Number(valuationDetails.meanValue) + (valuationDetails.meanValue * companyImportDetails.regionDuties / 100) + (valuationDetails.meanValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(valuationDetails.meanValue) + (valuationDetails.meanValue * companyImportDetails.regionDuties / 100) + (valuationDetails.meanValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                  </tr>
                  <tr>
                    <td><b>Median Value</b></td>
                    <td>{((Number(valuationDetails.medianValue) + (valuationDetails.medianValue * companyImportDetails.regionDuties / 100) + (valuationDetails.medianValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                    <td>{(((Number(valuationDetails.medianValue) + (valuationDetails.medianValue * companyImportDetails.regionDuties / 100) + (valuationDetails.medianValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                    <td>{(((((Number(valuationDetails.medianValue) + (valuationDetails.medianValue * companyImportDetails.regionDuties / 100) + (valuationDetails.medianValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(valuationDetails.medianValue) + (valuationDetails.medianValue * companyImportDetails.regionDuties / 100) + (valuationDetails.medianValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                  </tr>
                  <tr>
                    <td><b>Recomended Value</b></td>
                    <td>{((Number(recomendedValue) + (Number(recomendedValue) * companyImportDetails.regionDuties / 100) + (Number(recomendedValue) * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                    <td>{(((Number(recomendedValue) + (Number(recomendedValue) * companyImportDetails.regionDuties / 100) + (Number(recomendedValue) * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                    <td>{(((((Number(recomendedValue) + (Number(recomendedValue) * companyImportDetails.regionDuties / 100) + (Number(recomendedValue) * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(recomendedValue) + (recomendedValue * companyImportDetails.regionDuties / 100) + (recomendedValue * companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                  </tr> */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>)}
        <br />
        {Object.keys(mpiData).length !== 0 ? (
          <Card style={{ width: '55rem', marginLeft: "10rem" }}>
            <Card.Body>
              {/* <Table striped>
                <thead>
                  <tr>
                    <th>VEHICLE MPI REPORT :</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Realistic Retail Price</b></td>
                    <td></td>
                    <td>{companyObject.companyCurrency === 'ZAR' ? (<>R {mpiData.realisticRetailPrice} </>) : (
                      <>R {((100 / 115 * mpiData.realisticRetailPrice) * 112 / 100).toFixed()} | P {((100 / 115 * mpiData.realisticRetailPrice * currentExchangeRate) * 112 / 100).toFixed()}</>)}</td>
                  </tr>
                  <tr>
                    <td><b>Realistic Wholesale Price</b></td>
                    <td></td>
                    <td>{companyObject.companyCurrency === 'ZAR' ? (<>R {mpiData.realisticWholesalePrice} </>) : (
                      <>R {((100 / 115 * mpiData.realisticWholesalePrice) * 112 / 100).toFixed()} | P {((100 / 115 * mpiData.realisticWholesalePrice * currentExchangeRate) * 112 / 100).toFixed()}</>)}</td>
                  </tr>
                </tbody>
              </Table> */}
            </Card.Body>
          </Card>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <br />
        <Card style={{ width: '73rem', height: '8rem' }} bg={'white'} text={'Black'}
          className="mb-2 text-right jp-blue">
          <Card.Body>
            <div style={{ marginLeft: "10rem" }}>
              POWERED BY
              <br />
              <img
                alt=""
                src={LSLogo}
                width="300"
                height="80"
                className="d-inline-block "
              />
              <img
                alt=""
                src={ASLogo}
                width="280"
                height="80"
                className="d-inline-block "
                style={{ marginLeft: '10px' }}
              />
              <img
                alt=""
                src={TULogo}
                width="300"
                height="80"
                className="d-inline-block"
                style={{ marginLeft: '10px' }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default DataComponent;