/* eslint-disable consistent-return */
import axios from 'axios';
import GLOBAL from '../config/global';
import encryptionHelper from '../helpers/encryptionHelper';

const headers = {
  headers: {
    'x-api-key': GLOBAL.API_AUTH_KEY,
  },
};

const getEncrypted = (data) => {
  let cipString = encryptionHelper.encrypt(data, GLOBAL.CHAT_API_SECRET);
  return cipString;
}

const getCompanies = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/getCompanies`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const addCompany = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/addCompany`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const setLogo = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/setCompanyLogo`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const setReportColors = async (filter) => {
  let data = getEncrypted(filter);
  const rtnObj = await axios.post(
    `${GLOBAL.API_URL}/setReportColors`,
    data,
    headers,
  );
  return rtnObj.data.message;
};

const Api = {
  setLogo,
  getCompanies,
  setReportColors,
  addCompany,
};

export default Api;
