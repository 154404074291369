import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//ADAPTERS 
import transResultsAdapter from '../../adapters/transResultsAdapter';

const Reports = function Reports() {
  const [validated, setValidated] = useState(false);
  const [searches, setSearches] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reportURL, setReportURL] = useState('');
  const [show, setShow] = useState(false);
  const [userfilter, setuserFilter] = useState('');
  const [companyFilter, setCompanyFilter] = useState('all');

  useEffect(() => {
    checkAuth();
    fetchData();
  }, [setSearches]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkAuth = () => {
    if (window.sessionStorage.getItem('authState') !== 'LOGGED_IN') {
      window.location.href = './login'
    }
  }
  const setActiveShow = async (index) => {
    setIsLoadingButton(true);
    let transResultsFound = await transResultsAdapter.getTransResults({ transResultId: searches[index].transResultId });
    setReportURL(transResultsFound[0].reportUrl);
    window.open(transResultsFound[0].reportUrl, '_blank', 'noopener,noreferrer');
    setIsLoadingButton(false);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let csvString = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      csvString += line + '\r\n';
    }
    return csvString;
  }

  const exportCSVFile = (headers, items, fileTitle) => {
    console.log('csv export')
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);
    let csv = convertToCSV(jsonObject);
    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const headers = {
    vehicleVin: 'Vehicle VIN',
    userId: "User",
    searchDate: "Searched Date",
    found: "Found"
  };

  const searchWithFilter = async () => {
    setIsLoading(true);

    let filter = {
      fromDate: fromDate,
      toDate: toDate,
      userId: userfilter,
      companyId: companyFilter
    }

    let foundCases = await transResultsAdapter.getSearches(filter);
    let arr = []
    searches.forEach(search => {
      let obj = {
        vehicleVin: search.vehicleVin,
        userId: search.userId,
        searchDate: search.searchDate,
        found: search.found,
      }
      arr.push(obj);
    });
    console.log(foundCases);
    setSearches(foundCases);
    setCsvData(arr);
    setIsLoading(false);
  }

  const fetchData = async () => {
    setIsLoading(true);
    let filteredSearches = [];
    let latestSearch = {};
    let filter = {
      companyId: window.sessionStorage.getItem('companyId'),
    }
    filteredSearches = await transResultsAdapter.getSearches(filter)
    filteredSearches.sort((a, b) => (a.searchDate < b.searchDate) ? 1 : -1);
    
    latestSearch = filteredSearches[0];
    latestSearch.latest = true;
    filteredSearches[0] = latestSearch;

    setSearches(filteredSearches);
    setIsLoading(false);
  }

  return (

    <>
      <br />
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center' }} >
          <Card>
            <Card.Header style={{ backgroundColor: 'grey' }}>
              SEARCH EVALUATIONS
            </Card.Header>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>FROM DATE</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>TO DATE</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom02">
                    <Form.Label>USER</Form.Label>
                    <Form.Select aria-label="Default select example"
                      onChange={(e) => setuserFilter(e.target.value)}>
                      <option value="all">ALL</option>
                      <option value="00">Josef Glad</option>
                    </Form.Select>
                  </Form.Group>
                  <Col>
                    <br />
                    <Button variant='flat' type="submit" onClick={searchWithFilter}>SEARCH</Button>
                  </Col>
                  <Col>
                    <br />
                    <Button onClick={() => exportCSVFile(headers, csvData, 'Searches export :' + new Date())}>Export</Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row >
        <Col style={{ display: 'flex', justifyContent: 'center' }} >
          <Col md="10">
            <Card>
              <Card.Header style={{ backgroundColor: 'grey' }}>
                EVALUATIONS
              </Card.Header>
              <Card.Body>
                <Table striped hover variant="light">
                  <thead>
                    <tr>
                      <th>FOUND</th>
                      <th>VIN</th>
                      <th>DATE</th>
                      <th>USER</th>
                      <th>PDF</th>
                    </tr>
                  </thead>
                  {!isLoading ? (
                    <tbody>
                      {searches.map((result, index) => (
                        <tr>
                          <td>{result.found ? "YES" : "NO"}</td>
                          <td>{result.vehicleVin}</td>
                          <td>{result.searchDate}</td>
                          <td>{result.userId}</td>
                          <td>
                            <Button variant="flat" onClick={() => setActiveShow(index)}>
                              {isLoadingButton ? (
                                <>
                                  <Spinner animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Loading...</span>
                                  </Spinner>
                                </>
                              ) : (
                                <>
                                  DOWNLOAD
                                </>
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </Row>
    </>

  );
};

export default Reports;