import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import { useHref } from 'react-router-dom';

const Users = function Users() {
  const [validated, setValidated] = useState(false);
  const [isloggedIn, setIsLoggedIn] = useState(true);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <Row>
        <Col>
          <br />
          <h3>USERS</h3>
          <Button variant="flat">ADD</Button>
        </Col>
      </Row>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Create Date</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Josef Peri Glad</td>
            <td>josef@apollostudios.co.za</td>
            <td>2022/01/01</td>
            <td>True</td>
            <td><Button variant="flat">UPDATE</Button></td>
          </tr>
        </tbody>
      </Table>
    </>    
  );
};

export default Users;