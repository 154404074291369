import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { toast } from 'react-toastify';
//Adapters
import userAdapter from '../../adapters/userAdapter'
import companyAdapter from '../../adapters/companyAdapter'

const Admin = function Admin() {
  const [validated, setValidated] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isloggedIn, setIsLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [show, setShow] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [users, setUsers] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyColors, setCompanyColors] = useState({});

  useEffect(() => {
    checkAuth();
    fetchData();
  }, [setCompanies]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkAuth = () => {
    if (window.sessionStorage.getItem('authState') !== 'LOGGED_IN') {
      window.location.href = './login'
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    let comp = window.sessionStorage.getItem('companyId');
    setCompanyId(comp)
    let filter = { companyId: comp }
    let foundCompanies = await companyAdapter.getCompanies(filter);
    let foundUsers = await userAdapter.getUsers(filter);
    setCompanies(foundCompanies);
    setUsers(foundUsers);
    setIsLoading(false);
  }

  const updateCompanyColor = (colorId,color) => {
    let newColor = companyColors;
    if (colorId === 'mainColor') {
    newColor.mainColor = color;
    } else if (colorId === 'accentColor') {
      newColor.accentColor = color;
    } else{
      newColor.secondAccentColor = color;
    }
    setCompanyColors(newColor);
  }

  const saveReportColors = async () => {
    let dataObj = {
      companyId:companyId,
      companyColors:companyColors
    }
    console.log(dataObj)
    let updatedCompany = await companyAdapter.setReportColors(dataObj);
  }

  const saveImage = (files) => {
    setSaving(true);
    // FileReader support
    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        const toastOptions = {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };
        document.getElementById('backgroundPreview').style.backgroundImage = `url("${fr.result}")`;
        let dataObj = {
          companyId:companyId,
          imageData:fr.result
        }
        companyAdapter.setLogo(dataObj)
          .then(() => toast.success('Company Logo Image Updated Successfully'), toastOptions)
          .catch(() => toast.error('Company Logo Image Failed to Update'), toastOptions)
          .finally(() => setSaving(false));
      };
      fr.readAsDataURL(files[0]);
    } else {
      setSaving(false);
    }
  };

  const setShowModal = (modalType) => {
    setModalState(modalType);
    handleShow();
  }

  const submitModalDetails = async () => {
    if (modalState === 'user') {

    } else if (modalState === 'company') {
      let createdCompany = await companyAdapter.addCompany({ companyName: companyName })
    } else if (modalState === 'user update') {

    } else {

    }
    fetchData();
    handleClose();
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <br />
      <Row>
        <Col>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <b>REPORT DETAILS</b>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Company Logo</Form.Label>
                    <Form.Control
                      id="backgroundImage"
                      type="file"
                      size="sm"
                      accept="image/*"
                      disabled={saving}
                      onChange={(e) => saveImage(e.target.files)}
                    />
                    <div id="backgroundPreview" style={{ width: '100%', paddingTop: '56.25%', backgroundSize: 'cover' }} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="exampleColorInput">Report Theme Colors</Form.Label>
                  <Row>
                    <Col>
                      BASE PAGE COLOR
                      <Form.Control
                        type="color"
                        defaultValue="#563d7c"
                        title="Choose your color"
                        onChange={(e) => updateCompanyColor('mainColor',e.target.value)}
                      />
                    </Col>
                    <Col>
                      ACCENT COLOR 1
                      <Form.Control
                        type="color"
                        defaultValue="#563d7c"
                        title="Choose your color"
                        onChange={(e) => updateCompanyColor('accentColor',e.target.value)}
                      />
                    </Col>
                    <Col>
                      ACCENT COLOR 2
                      <Form.Control
                        type="color"
                        defaultValue="#563d7c"
                        title="Choose your color"
                        onChange={(e) => updateCompanyColor('secondAccentColor',e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Button onClick={saveReportColors}>
                    SAVE
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <br />
          <h3>USERS</h3>
          <Button variant="flat" onClick={() => setShowModal('user')}>ADD</Button>
        </Col>
      </Row>
      <br />
      <Card>
        <Card.Body>
          <Table striped hover variant="light">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Company ID</th>
                <th>Company Name</th>
                <th>Create Date</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                users.map(user =>
                (
                  <tr>
                    <td>{user.fullName}</td>
                    <td>{user.email}</td>
                    <td>{user.companyId}</td>
                    <td></td>
                    <td>{user.createDate}</td>
                    <td>{user.active ? "YES" : "NO"}</td>
                    <td><Button variant="flat" onClick={() => setShowModal('user update')}>UPDATE</Button></td>
                  </tr>
                ))
              ) : null}
            </tbody>
          </Table>
        </Card.Body>
      </Card> */}
    </>
  );
};

export default Admin;