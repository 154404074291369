import React from 'react';
import { Routes, Route, } from 'react-router-dom';
import globals from './config/global';

//component imports
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ToastContainer } from 'react-toastify';

//pages imports
import Login from './components/login';
import PaymentForm from './components/paymentForm';
import Search from './components/search';
import Users from './components/users';
import Admin from './components/admin';
import Reports from './components/reports';
import Company from './components/companyConfig';

//style imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  const logOut = () => {
    window.sessionStorage.clear();
    window.location.href = "./"
  }
  return (
    <div className="App" style={{ height: '100vh' }} >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <style type="text/css">
        {`
    .btn-flat {
      background-color: #00c2cb;
      color: white;
    }

    .btn-flat-pink {
      background-color: #cb00c2;
      color: white;
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
      </style>
      {window.location.href !== globals.BaseURL ? (
        <Navbar className='color-nav' collapseOnSelect expand="xl" style={{ backgroundColor: '#d9d9d9' }}>
          <Container>
            <Navbar.Brand href="./search">
              <img
                alt=""
                src="/logo-name.png"
                width="140"
                height="70"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              {window.sessionStorage.getItem('authState') === 'LOGGED_IN' ? (
                <Nav>
                  <NavDropdown title={window.sessionStorage.getItem('fullName')} id="collasible-nav-dropdown">
                    {window.sessionStorage.getItem('role') === 'superadmin' ? (
                      <>
                        <NavDropdown.Item href="./admin">
                          ADMIN MANGEMENT
                        </NavDropdown.Item>
                        <NavDropdown.Item href="./CompanyDetails">
                          COMPANY CONFIGURATION
                        </NavDropdown.Item>
                        <NavDropdown.Item href="./PaymentForm">
                          PAYMENT INFORMATION
                        </NavDropdown.Item>
                      </>
                    ) : null}
                    {window.sessionStorage.getItem('role') === 'admin' ? (
                      <>
                        <NavDropdown.Item href="./CompanyDetails">
                          COMPANY CONFIGURATION
                        </NavDropdown.Item>
                      </>
                    ) : null}
                    <NavDropdown.Item href="./reports">
                      REPORTS
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logOut}>
                      LOG IN/LOG OUT
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              ) : null}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : null}
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/CompanyDetails" element={<Company />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/paymentForm" element={<PaymentForm />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/reports" element={<Reports />} />
      </Routes>
    </div>
  );
}

export default App;
