import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { filterBy } from '@progress/kendo-data-query';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

//Custom Components
import { MyCommandCell } from "./myCommandCell";
import PdfComponent from '../pdfComponent/PdfComponent';

//Styling 
import './search.css';
import 'react-toastify/dist/ReactToastify.css';
import '@progress/kendo-theme-default/dist/all.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//ADAPTERS 
import transResultsAdapter from '../../adapters/transResultsAdapter';
import companyAdapter from '../../adapters/companyAdapter';
import paymentAdapter from '../../adapters/paymentAdapter';
import makeModelAdapter from '../../adapters/makeModelAdapter'

const initialFilter = {
  logic: "or",
  filters: [{
    field: "vehicleVin",
    operator: "contains",
    value: ""
  }]
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const extraOptions = [
  { value: "Cattle Rails", label: "Cattle Rails" },
  { value: "Rubberized Load Bin", label: "Rubberized Load Bin" },
  { value: "Tow Bar", label: "Tow Bar" },
  { value: "Nudge Bar", label: "Nudge Bar" },
  { value: "Snorkel", label: "Snorkel" },
  { value: "Side Steps", label: "Side Steps" },
  { value: "Roll Bar", label: "Roll Bar" },
  { value: "Reverse camera", label: "Reverse camera" },
  { value: "Extra Lights", label: "Extra Lights" },
  { value: "Craft Exhaust System", label: "Craft Exhaust System" },
  { value: "Body Kit", label: "Body Kit" },
  { value: "Tonneau Cover", label: "Tonneau Cover" },
];

const Search = function Search() {
  const [validated, setValidated] = useState(false);
  const [isGreySearchEnabled, setIsGreySearchEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isEvalButton, setIsEvalButton] = useState(false);
  const [isFullSearch, setIsFullSearch] = useState(false);
  const [isSecondSearch, setIsSecondSearch] = useState(false);
  const [searches, setSearches] = useState([]);
  const [activeSearch, setActiveSearch] = useState({});
  const [greyImport, setGreyImport] = useState({});
  const [filter, setFilter] = useState(initialFilter);
  const [show, setShow] = useState(false);
  const [showR, setShowR] = useState(false);
  const [isGreyVehicle, setIsGreyVehicle] = useState(false);
  const [vehicleVin, setVehicleVin] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [vehicleReg, setVehicleReg] = useState('');
  const [vehicleClient, setVehicleClient] = useState('NOT SUPPLIED');
  const [companyCurrency, setCompanyCurrency] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [companyObject, setCompanyObject] = useState({});
  const [transResultObject, setTransResultObject] = useState({});
  const [token, setToken] = useState('');
  const [billingType, setBillingType] = useState('');
  const [tokenAmount, setTokenAmount] = useState(500);
  const [vehicleMileage, setVehicleMileage] = useState(0);
  const [currentExchangeRate, setCurrentExchangeRate] = useState(1);
  const [currentExchangeRateObject, setCurrentExchangeRateObject] = useState({});
  const [vehicleManYear, setVehicleManYear] = useState(0);
  const [makeModelList, setMakeModelList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vehicleMake, setVehicleMake] = useState({});
  const [vehicleShortDesc, setVehicleShortDesc] = useState("");
  const [vehicleModel, setVehicleModel] = useState({});
  const [vehicleModelCode, setVehicleModelCode] = useState("");
  const [vehicleCondition, setVehicleCondition] = useState('G');
  const [vehicleTransmission, setVehicleTransmission] = useState('Manual');
  const [vehicleDrive, setVehicleDrive] = useState('2x4');
  const [vehicleBodyStyle, setVehicleBodyStyle] = useState('sedan');
  const [evaluateButtonText, setEvaluateButtonText] = useState('Evaluate');
  const [trimLevels, setTrimLevels] = useState([]);
  const [trimOptions, setTrimOptions] = useState([]);
  const [vehicleTrim, setVehicleTrim] = useState("");
  const [otherVehicleTrim, setOtherVehicleTrim] = useState("");
  const [freightAndLandingCost, setfreightAndLandingCost] = useState("");
  const [optionSelected, setOptionSelected] = useState(null);

  useEffect(() => {
    checkAuth();
    fetchData();
  }, [setSearches, setMakeModelList]);

  const handleChange = (selected) => {
    console.log(optionSelected);
    setOptionSelected(selected);
  };

  const setVehicleTrimButtonPressed = async (trim) => {
    setVehicleTrim(trim);
    toast.info(trim + " trim selected");
    if (trim !== "OTHER") {
      await evaluateGreyVehicle(trim);
    }
  }

  const checkVehicleVin = async (vin) => {
    if (vin.length !== 17) {
      setIsGreyVehicle(true);
    }
    setVehicleVin(vin)
  }

  const confirmSearch = () => {
    let ammountDisplayed = tokenAmount.toString();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>SEARCH ?</h1>
            {billingType === 'CC' ? (
              <p>You are about to perform a vehicle search for {companyCurrency === 'ZAR' ? "R" : "P"} {ammountDisplayed.slice(0, -2)}</p>
            ) : (
              <p>You are about to perform a vehicle search</p>
            )}
            <p>You will not be charged for searches that do not return results.</p>
            <Row>
              <Col>
                <Button variant="danger" onClick={onClose}>Cancel</Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    onClose();
                    clickSubmit();
                  }}
                >
                  Proceed
                </Button>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        );
      }
    });
  }

  const loadingDetails = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Loading...</h1>
            <Row>
              <Col>
                <Button hidden id="loading-screen-button" variant="danger" onClick={onClose}></Button>
              </Col>
            </Row>
          </div>
        );
      }
    });
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseR = () => setShowR(false);
  const handleShowR = () => setShowR(true);

  const payForRecord = async (dataItem) => {
    setIsLoadingButton(true);
    let AmountToCharge = tokenAmount;
    if (companyCurrency !== 'ZAR') {
      AmountToCharge = tokenAmount / currentExchangeRate;
    }
    let details = {
      token: token,
      amount: AmountToCharge.toFixed(),
      searchId: dataItem.searchId,
    }
    let validPayment = await paymentAdapter.chargeTokenPayment(details);
    if (validPayment) {
      toast.info('Payment success');
      fetchData();
    } else {
      toast.error('Payment failed');
      fetchData();
    }
    setIsLoadingButton(false);
  }

  function clickSubmit() {
    document.getElementById("formSubmitButton").click();
  }

  const clearForm = () => {
    setValidated(false);
    setVehicleShortDesc("");
    setVehicleModelCode("");
    setIsSecondSearch(false);
    setVehicleVin("");
    setVehicleMake({});
    setVehicleModel({});
    setTrimLevels([]);
    setTrimOptions([]);
    setVehicleTrim("");
    setIsFullSearch(false);
    setIsEvalButton(false);
    setIsGreyVehicle(false);
    document.getElementById("searchForm").reset();
  }

  function stopLoading() {
    document.getElementById("loading-screen-button").click();
    document.getElementById("searchForm").reset();
  }

  const expandChange = (event) => {
    let newData = searches.map((item) => {
      if (item.vehicleVin === event.dataItem.vehicleVin) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setSearches(newData);
  };

  const setModelListFilter = (id) => {
    let item = makeModelList.find(mm => mm.make.id === Number(id));
    setVehicleMake(item.make);
    if (item !== undefined) {
      setModelList(item.model_list);
    }
  }

  const modelSelected = async (model) => {
    setVehicleModel(model);
    toast.info(model.name + " selected as Model");
    await evaluateGreyVehicle();
  }

  const setActiveShow = async (dataItem) => {
    if (dataItem.isGrey === true) {
      loadingDetails();
      toast.info("Getting info");
      let greyImportVehicleFound = await transResultsAdapter.getGreyImport({ vehicleId: dataItem.greyImportVehicleId });
      setGreyImport(greyImportVehicleFound);
      stopLoading();
    }
    var d1 = new Date()
    var str = d1.toISOString().slice(0, -5)
    setCurrentDateTime(str);
    setActiveSearch(dataItem);
    handleShow();
  }

  const setActiveShowR = async (dataItem) => {
    loadingDetails();
    let transResultsFound = await transResultsAdapter.getTransResults({ transResultId: dataItem.transResultId });
    if (dataItem.isGrey === true) {
      let greyImportVehicleFound = await transResultsAdapter.getGreyImport({ vehicleId: dataItem.greyImportVehicleId });
      setGreyImport(greyImportVehicleFound);
    }
    setActiveSearch(dataItem);
    setTransResultObject(transResultsFound);
    stopLoading();
    handleShowR();
  }

  const checkAuth = async () => {
    if (window.sessionStorage.getItem('authState') !== 'LOGGED_IN') {
      window.location.href = './login'
    } else {
      let currCompanyId = window.sessionStorage.getItem('companyId');
      let billing = window.sessionStorage.getItem('billingType');
      setBillingType(billing);
      setCompanyId(currCompanyId);
      let currExchangeRate = window.sessionStorage.getItem('xrate');
      let currExchangeRateObject = { 'ZARToBWP': window.sessionStorage.getItem('xrate'), 'UsdToZar': window.sessionStorage.getItem('UsdToZar'), 'UsdToBwp': window.sessionStorage.getItem('UsdToBwp') };
      setCurrentExchangeRate(currExchangeRate);
      setCurrentExchangeRateObject(currExchangeRateObject);

      let foundCompany = {}

      if (window.sessionStorage.getItem('paymentToken') === '' || window.sessionStorage.getItem('paymentToken') === undefined) {
        foundCompany = await companyAdapter.getCompanies({ companyId: currCompanyId });
        let compCurr = foundCompany[0].companyCurrency;
        setCompanyCurrency(compCurr);
        setCompanyObject(foundCompany[0]);
        if (foundCompany.length > 0) {
          setToken(foundCompany[0].token);
          setTokenAmount(foundCompany[0].tokenAmount);
        } else {
          window.location.href = './PaymentForm';
        }
      } else {
        setToken(window.sessionStorage.getItem('paymentToken'));
        foundCompany = await companyAdapter.getCompanies({ companyId: currCompanyId });
        let compCurr = foundCompany[0].companyCurrency;
        setCompanyCurrency(compCurr);
        setCompanyObject(foundCompany[0]);
        if (foundCompany.length > 0) {
          setTokenAmount(foundCompany[0].tokenAmount);
        }
      }
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    let filteredSearches = [];
    let makeModels = [];
    let latestSearch = {};
    let filter = {
      companyId: window.sessionStorage.getItem('companyId'),
    }
    filteredSearches = await transResultsAdapter.getSearches(filter)
    filteredSearches.sort((a, b) => (a.searchDate < b.searchDate) ? 1 : -1);

    let currExchangeRate = window.sessionStorage.getItem('xrate');
    let currExchangeRateObject = { 'ZARToBWP': window.sessionStorage.getItem('xrate'), 'UsdToZar': window.sessionStorage.getItem('UsdToZar'), 'UsdToBwp': window.sessionStorage.getItem('UsdToBwp') };
    let foundCompany = {}
    let currCompanyId = window.sessionStorage.getItem('companyId');
    foundCompany = await companyAdapter.getCompanies({ companyId: currCompanyId });
    let freightAndLanding = (foundCompany[0].companyImportDetails.insuranceBond + (foundCompany[0].companyImportDetails.seaFreight * currExchangeRateObject.UsdToBwp) + (foundCompany[0].companyImportDetails.roadFreight * currExchangeRate)).toFixed();

    setfreightAndLandingCost(freightAndLanding);

    latestSearch = filteredSearches[0];
    latestSearch.latest = true;
    filteredSearches[0] = latestSearch;

    for (let index = 0; index < filteredSearches.length; index++) {
      let element = filteredSearches[index];
      let dateTime = new Date(element.searchDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
      new Intl.DateTimeFormat('en-US', options).format(dateTime)

      let shortDateTime = dateTime.toString();

      filteredSearches[index].searchDate = shortDateTime.substring(0, 21);
    }

    makeModels = await makeModelAdapter.getMakeModels({ makeId: "" });

    // for (let index = 0; index < makeModels.length; index++) {
    //   const makeArr = makeModels[index];
    //   let moddelArr = makeArr.model_list;

    //   for (let i = 0; i < moddelArr.length; i++) {
    //     const nameWithoutParentheses = moddelArr[i].name.replace(/\s*\([^)]*\)\s*/, '');
    //     moddelArr[i].name = nameWithoutParentheses;
    //   }

    //   console.log(moddelArr);
    // }

    setSearches(filteredSearches);
    setMakeModelList(makeModels);
    setIsLoading(false);
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    let testYear = new Date().getFullYear()
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      toast.error('Required Fields Missing.');
    } else {
      let testVin = document.getElementById("vinInput").value;
      let testReg = document.getElementById("regInput").value;
      let testMil = document.getElementById("milInput").value;
      let testVehYear = document.getElementById("vehYearInput").value;
      let testClientName = document.getElementById("clientNameInput").value;
      if (testVin === "" || testReg === "" || testMil === "" || testVehYear === "" || testClientName === "") {
        event.preventDefault();
        toast.error('Required Fields Missing');
        setValidated(false);
      } else if (Number(vehicleManYear) <= 1994 || Number(vehicleManYear) >= (testYear + 1)) {
        event.preventDefault();
        toast.error(`Manufacture year must be between 1995 and ${testYear + 1}`);
      } else if (vehicleClient === "" || vehicleCondition === "" || vehicleMileage === "" || vehicleVin === "" || vehicleReg === "") {
        event.preventDefault();
        toast.error('Required Fields Missing.');
        setValidated(false);
      } else if (isFullSearch === true && vehicleMake.name === undefined === null && vehicleModel.name === undefined) {
        event.preventDefault();
        toast.error('Required Additional Fields Missing.');
      } else {
        event.preventDefault();
        setValidated(true);
        setIsEvalButton(true);
        setEvaluateButtonText("Looking For Vehicle");
        toast.info('CHECKING VIN FOR DUPICATES');
        if (isSecondSearch === true && isGreySearchEnabled !== false) {
          await evaluateGreyVehicle();
        } else {
          let foundSearches = await transResultsAdapter.checkVinExists({ vehicleVin: vehicleVin, companyId: companyId });
          if (foundSearches.length === 0) {
            if (vehicleVin.length === 17) {
              await evaluate17Vehicle();
            } else if (isGreySearchEnabled !== false) {
              setIsSecondSearch(true);
              await evaluateGreyVehicle();
            } else {
              toast.error("VIN Lookup Failed");
              setIsEvalButton(false);
              setIsFullSearch(false);
            }
          } else {
            setIsEvalButton(false);
            toast.error('VIN Number already searched ,filter on the table below to view.');
            setIsFullSearch(false);
            clearForm();
          }
        }
      }
    }
  };


  const evaluate17Vehicle = async () => {
    let newSearch = {};
    let foundSearches = [];

    let vehicleCriteria = {
      userId: window.sessionStorage.getItem('username'),
      companyId: window.sessionStorage.getItem('companyId'),
      vehicle_mileage: parseInt(vehicleMileage),
      vehicleModel: vehicleModel.name,
      vehicleMake: vehicleMake.name,
      shortVehicleDesc: "",
      vehicleClient,
      vehicleReg,
      vin_no: vehicleVin,
      vehicle_manufacture_year: vehicleManYear,
      condition: vehicleCondition
    }
    toast.info('VIN CHECK PASSED');
    toast.info('DOING VEHICLE TRANSUNION LOOKUP');
    newSearch = await transResultsAdapter.evaluateVehicle(vehicleCriteria);

    if (newSearch.found === true) {
      if (billingType === 'CC') {
        let AmountToCharge = tokenAmount;
        if (companyCurrency !== 'ZAR') {
          AmountToCharge = tokenAmount / currentExchangeRate;
        }
        if (newSearch.found === true) {
          let details = {
            token: token,
            amount: AmountToCharge.toFixed(),
            searchId: newSearch.searchId,
          }
          let validPayment = await paymentAdapter.chargeTokenPayment(details);
          clearForm();
          if (validPayment) {
            toast.info('Payment success');
          } else {
            toast.error('Payment failed');
          }
        } else {
          toast.error('TRANSUNION LOOKUP FAILED');
          setIsEvalButton(false);
        }
      }
    } else {
      if (newSearch.error === true) {
        toast.error('SYSTEM Error, please contact support.');
      } else {
        toast.error('TRANSUNION LOOKUP FAILED');
        toast.info('DOING LIGHTSTONE VEHICLE LOOKUP');
        newSearch = await transResultsAdapter.doLightstoneLookup(vehicleCriteria);
        if (newSearch.found === true) {
          toast.info('PARTIAL SEARCH SUCCESS');
          if (billingType === 'CC') {
            let AmountToCharge = tokenAmount;
            if (companyCurrency !== 'ZAR') {
              AmountToCharge = tokenAmount / currentExchangeRate;
            }
            if (newSearch.found === true) {
              let details = {
                token: token,
                amount: AmountToCharge.toFixed(),
                searchId: newSearch.searchId,
              }
              let validPayment = await paymentAdapter.chargeTokenPayment(details);
              clearForm();
              if (validPayment) {
                toast.info('Payment success');
              } else {
                toast.error('Payment failed');
              }
            } else {
              toast.error('TRANSUNION LOOKUP FAILED');
              setIsEvalButton(false);
            }
          }
        } else {
          toast.error('LIGHTSTONE LOOKUP FAILED');
          toast.info('DOING MARKET LOOKUP');
          let gptLookup = await transResultsAdapter.getVehicleByVin(vehicleCriteria);
          if (gptLookup.found === true) {
            toast.info('Vehicle Found :');
            toast.info(gptLookup.vehicleMake + ' ' + gptLookup.vehicleModel);
            toast.info('Getting Valuation Information');
            setEvaluateButtonText("Checking Market Data");
            foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
            if (foundSearches.message === "No Vehicle Match") {
              toast.info('Retrying Valuation Information');
              foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
              if (foundSearches.message === "No Vehicle Match") {
                toast.info('Retrying Valuation Information');
                foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
              }
            }
            setIsFullSearch(false);
            toast.info('Valuation Found');
            toast.info('Report Ready');
            clearForm();
            fetchData();
            setIsEvalButton(false);
          } else {
            toast.error('Vehicle was not found');
            toast.error('Check supplied VIN');
            setIsEvalButton(false);
          }
        }
      }
    }
    setIsEvalButton(false);
  }

  const evaluateGreyVehicle = async () => {

    setIsEvalButton(true);

    let foundSearches = [];
    let SelectedExtras = [];
    if (optionSelected !== null) {
      for (var index = 0; index < optionSelected.length; index++) {
        let value = optionSelected[index].value;
        SelectedExtras.push(value);
      }
    }

    let vehicleCriteria = {
      userId: window.sessionStorage.getItem('username'),
      companyId: window.sessionStorage.getItem('companyId'),
      vehicle_mileage: parseInt(vehicleMileage),
      vehicleModel: vehicleModel.name,
      vehicleModelCode: vehicleModelCode,
      selectedExtras: SelectedExtras,
      vehicleMake: vehicleMake.name,
      vehicleDrive: vehicleDrive,
      vehicleBodyStyle: vehicleBodyStyle,
      vehicleTransmission: vehicleTransmission,
      shortVehicleDesc: "",
      vehicleTrim: " ",
      vehicleClient,
      vehicleReg,
      vin_no: vehicleVin,
      vehicle_manufacture_year: vehicleManYear,
      condition: vehicleCondition
    }

    toast.info('DOING VEHICLE GREY IMPORT LOOKUP');
    setEvaluateButtonText("Locating Vehicle");

    if (vehicleShortDesc === "" && vehicleMake.name === undefined) {
      let chassisLookup = await transResultsAdapter.doChassisLookup(vehicleCriteria);
      if (chassisLookup.message === "No Vehicle Match") {
        toast.error('Chassis lookup failed, please do manual search.');
        setEvaluateButtonText("");
        setIsFullSearch(true);
        setIsEvalButton(false);
        return;
      } else {
        let chassisString = chassisLookup.shortVehicleDesription;
        let stringArray = chassisString.split(" ");
        let lastIndex = stringArray.length - 1;

        let returnString = "";
        let testMakemodelCode = stringArray[lastIndex];
        testMakemodelCode = testMakemodelCode.replace('#', '');

        setVehicleModelCode(testMakemodelCode);
        for (let index = 0; index < stringArray.length - 3; index++) {
          if (stringArray[index] !== " ") {
            if (index === 0) {
              returnString = stringArray[index];
            } else {
              returnString = returnString + " " + stringArray[index];
            }
          }
        }

        returnString = returnString + " " + stringArray[lastIndex]

        let modelStrinArr = returnString.split(" ");
        modelStrinArr.splice(0, 1);
        modelStrinArr.splice((modelStrinArr.length - 1), 1);

        let modelString = "";
        for (let index = 0; index < modelStrinArr.length; index++) {
          if (modelStrinArr[index] !== " ") {
            if (index === 0) {
              modelString = modelStrinArr[index];
            } else {
              modelString = modelString + " " + modelStrinArr[index];
            }
          }
        }

        setVehicleShortDesc(returnString);
        setVehicleMake({ name: stringArray[0] })
        setVehicleModel({ name: modelString.trim() });
        vehicleCriteria.shortVehicleDesc = returnString;
        vehicleCriteria.make = stringArray[0];
        vehicleCriteria.model = modelString.trim();
      }
    }

    setEvaluateButtonText("Locating Vehicle Specs");
    let gptLookup = await transResultsAdapter.doSpecLookup(vehicleCriteria);
    if (gptLookup.message === "No Vehicle Match") {
      toast.error('Vehicle Spec was not found, Retrying.');
      let gptLookup = await transResultsAdapter.doSpecLookup(vehicleCriteria);

      if (gptLookup.message !== "No Vehicle Match") {
        toast.info('Vehicle Spec Found for :');
        toast.info(gptLookup.vehicleMake + ' ' + gptLookup.vehicleModel);
        setEvaluateButtonText("Doing valuation");
        foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
        if (foundSearches.message === "No Vehicle Match") {
          toast.info('Retrying Valuation');
          foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
        }

        toast.info('Valuation Found');
        setIsFullSearch(false);
        fetchData();
        setIsEvalButton(false);
        clearForm();
        fetchData();
      } else {
        toast.error('Vehicle valuation not found');
        setIsEvalButton(false);
        return;
      }

    } else {

      toast.info('Vehicle Found :');
      toast.info(gptLookup.vehicleMake + ' ' + gptLookup.vehicleModel);
      setEvaluateButtonText("Doing Valuation");
      foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
      if (foundSearches.message === "No Vehicle Match") {
        toast.info('Retrying Valuation');
        foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
        if (foundSearches.message === "No Vehicle Match") {
          toast.info('Retrying Valuation');
          foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
        }
      }

      toast.info('Valuation Found');
      toast.info('See table below');
      setIsFullSearch(false);
      setIsEvalButton(false);
      clearForm();
      fetchData();

    }

  }

  const evaluateNewGreyVehicle = async (trim) => {
    setIsEvalButton(true);
    if (vehicleTrim === "" && trim === undefined) {
      let vehicleCriteria = {
        userId: window.sessionStorage.getItem('username'),
        companyId: window.sessionStorage.getItem('companyId'),
        vehicle_mileage: parseInt(vehicleMileage),
        vehicleModel: vehicleModel.name,
        vehicleModelCode: vehicleModelCode,
        vehicleMake: vehicleMake.name,
        shortVehicleDesc: "",
        vehicleTrim: vehicleTrim === "OTHER" ? otherVehicleTrim : vehicleTrim,
        vehicleClient,
        vehicleReg,
        vin_no: vehicleVin,
        vehicle_manufacture_year: vehicleManYear,
        condition: vehicleCondition
      }
      toast.info('DOING VEHICLE GREY IMPORT LOOKUP');
      setEvaluateButtonText("Locating Vehicle");
      if (vehicleShortDesc === "" && vehicleMake.name === undefined) {
        let chassisLookup = await transResultsAdapter.doChassisLookup(vehicleCriteria);
        if (chassisLookup.message === "No Vehicle Match") {
          toast.error('Chassis lookup failed, please do manual search.');
          setEvaluateButtonText("");
          setIsFullSearch(true);
          setIsEvalButton(false);
          return;
        } else {
          let chassisString = chassisLookup.shortVehicleDesription;
          let stringArray = chassisString.split(" ");
          let lastIndex = stringArray.length - 1;

          let returnString = "";
          let testMakemodelCode = stringArray[lastIndex];
          testMakemodelCode = testMakemodelCode.replace('#', '');

          setVehicleModelCode(testMakemodelCode);
          for (let index = 0; index < stringArray.length - 3; index++) {
            if (stringArray[index] !== " ") {
              if (index === 0) {
                returnString = stringArray[index];
              } else {
                returnString = returnString + " " + stringArray[index];
              }
            }
          }

          returnString = returnString + " " + stringArray[lastIndex]

          let modelStrinArr = returnString.split(" ");
          modelStrinArr.splice(0, 1);
          modelStrinArr.splice((modelStrinArr.length - 1), 1);

          let modelString = "";
          for (let index = 0; index < modelStrinArr.length; index++) {
            if (modelStrinArr[index] !== " ") {
              if (index === 0) {
                modelString = modelStrinArr[index];
              } else {
                modelString = modelString + " " + modelStrinArr[index];
              }
            }
          }

          setVehicleShortDesc(returnString);
          setVehicleMake({ name: stringArray[0] })
          setVehicleModel({ name: modelString.trim() });
          vehicleCriteria.shortVehicleDesc = returnString;
          vehicleCriteria.make = stringArray[0];
          vehicleCriteria.model = modelString.trim();
        }

      }

      if (trimLevels.length === 0) {
        let trimDetails = await transResultsAdapter.doVehicleTrimLookup(vehicleCriteria);
        if (trimDetails.message === "No Vehicle Match") {
          trimDetails = await transResultsAdapter.doVehicleTrimLookup(vehicleCriteria);
          if (trimDetails.message === "No Vehicle Match") {
            trimDetails = await transResultsAdapter.doVehicleTrimLookup(vehicleCriteria);
            if (trimDetails.message === "No Vehicle Match") {
              toast.error('Trim was not found');
              setIsEvalButton(false);
            } else {
              setTrimLevels(trimDetails.trim_levels);
            }
          } else {
            setTrimLevels(trimDetails.trim_levels);
          }
        } else {
          setTrimLevels(trimDetails.trim_levels);
        }
        setEvaluateButtonText("");
        setIsEvalButton(false);
      }
    } else {
      toast.info('Getting Vehicle Specifications');
      setEvaluateButtonText("Checking Vehicle Data");
      let foundSearches = [];
      let vehicleCriteria = {
        userId: window.sessionStorage.getItem('username'),
        companyId: window.sessionStorage.getItem('companyId'),
        vehicle_mileage: parseInt(vehicleMileage),
        vehicleModel: vehicleModel.name,
        vehicleMake: vehicleMake.name,
        vehicleModelCode: vehicleModelCode,
        shortVehicleDesc: vehicleShortDesc,
        vehicleTrim: trim === "OTHER" ? otherVehicleTrim : trim,
        vehicleClient,
        vehicleReg,
        vin_no: vehicleVin,
        vehicle_manufacture_year: vehicleManYear,
        condition: vehicleCondition
      }

      // if (trimOptions.length === 0) {
      //   let optionDetails = await transResultsAdapter.doVehicleOptionLookup(vehicleCriteria);
      //   if (optionDetails.message === "No Vehicle Match") {
      //     optionDetails = await transResultsAdapter.doVehicleOptionLookup(vehicleCriteria);
      //     if (optionDetails.message === "No Vehicle Match") {
      //       optionDetails = await transResultsAdapter.doVehicleOptionLookup(vehicleCriteria);
      //       if (optionDetails.message === "No Vehicle Match") {
      //         toast.error('Trim Options was not found');
      //       }
      //     } else {
      //       toast.info("Trim Options Found");
      //       setTrimOptions(optionDetails.options);
      //       vehicleCriteria.trimOptions = optionDetails.options;
      //     }
      //   } else {
      //     toast.info("Trim Options Found");
      //     setTrimOptions(optionDetails.options);
      //     vehicleCriteria.trimOptions = optionDetails.options;
      //   }
      // }

      // let makeModel = await transResultsAdapter.getMakeModelIds({ make: vehicleCriteria.vehicleMake, model: vehicleCriteria.vehicleModel });

      // if (makeModel.makeId && makeModel.message !== "No Vehicle Match") {
      //   toast.info("DOING BF LOOKUP");
      //   let bfObject = {
      //     make: makeModel.makeId,
      //     model: makeModel.modelId,
      //     modelCode: vehicleCriteria.vehicleModelCode,
      //     fromYear: vehicleCriteria.vehicle_manufacture_year,
      //     toYear: vehicleCriteria.vehicle_manufacture_year,
      //     minMileage: (Number(vehicleCriteria.vehicle_mileage) - 10000),
      //     maxMileage: (Number(vehicleCriteria.vehicle_mileage) + 10000),
      //   }

      //   let bfValuation = await transResultsAdapter.getVehicleValuation(bfObject);
      //   vehicleCriteria.bfValuation = bfValuation;
      // }

      let gptLookup = await transResultsAdapter.doSpecLookup(vehicleCriteria);
      if (gptLookup.message === "No Vehicle Match") {
        toast.error('Vehicle Spec was not found, Retrying.');
        setEvaluateButtonText("Locating Vehicle Specs");
        let gptLookup = await transResultsAdapter.doSpecLookup(vehicleCriteria);
        if (gptLookup.message !== "No Vehicle Match") {
          toast.info('Vehicle Spec Found for :');
          toast.info(gptLookup.vehicleMake + ' ' + gptLookup.vehicleModel);
          setEvaluateButtonText("Doing valuation");
          foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
          if (foundSearches.message === "No Vehicle Match") {
            toast.info('Retrying Valuation');
            foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
            if (foundSearches.message === "No Vehicle Match") {
              toast.info('Retrying Valuation');
              foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
            }
          }
          toast.info('Valuation Found');
          setIsFullSearch(false);
          fetchData();
          setIsEvalButton(false);
          clearForm();
          fetchData();
        } else {
          toast.error('Vehicle valuation not found');
          setIsEvalButton(false);
          return;
        }
      } else {
        toast.info('Vehicle Found :');
        toast.info(gptLookup.vehicleMake + ' ' + gptLookup.vehicleModel);
        setEvaluateButtonText("Doing Valuation");
        foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
        if (foundSearches.message === "No Vehicle Match") {
          toast.info('Retrying Valuation');
          foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
          if (foundSearches.message === "No Vehicle Match") {
            toast.info('Retrying Valuation');
            foundSearches = await transResultsAdapter.doVehicleValuation({ searchId: gptLookup.searchId });
          }
        }
        toast.info('Valuation Found');
        toast.info('See table below');
        setIsFullSearch(false);
        setIsEvalButton(false);
        clearForm();
        fetchData();
      }
    }
  }

  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      isloading={isLoadingButton}
      isBilled={billingType}
      view={setActiveShow}
      report={setActiveShowR}
      payNow={payForRecord}
    />
  );

  return (
    <div className="Search">
      <>
        <br />
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }} >
            <Card>
              <Card.Header style={{ backgroundColor: 'grey' }}>
                NEW EVALUATION
              </Card.Header>
              <Card.Body>
                <Form id='searchForm' validated={validated} onSubmit={handleSubmit}>
                  <>
                    <Row>
                      <Form.Group as={Col} md="2">
                        <Form.Label>
                          VIN <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="vinInput"
                          onBlur={(e) => checkVehicleVin((e.target.value).trim())}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2" >
                        <Form.Label>REG<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Control
                          type="text"
                          id="regInput"
                          onChange={(e) => setVehicleReg((e.target.value).trim())}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2"  >
                        <Form.Label>MILEAGE (KM)<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Control
                          type="number"
                          id="milInput"
                          onChange={(e) => setVehicleMileage(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2"  >
                        <Form.Label>VEHICLE YEAR<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Control
                          type="number"
                          id="vehYearInput"
                          onChange={(e) => setVehicleManYear(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2"  >
                        <Form.Label>CONDITION<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setVehicleCondition(e.target.value)}>
                          <option></option>
                          {Number(vehicleMileage) > 100000 || Number(vehicleManYear) < 2004 ? (
                            <>
                              <option value="G">Good</option>
                              <option value="P">Poor</option>
                              <option value="VP">Very Poor</option>
                            </>
                          ) : (
                            <>
                              <option value="E">Excellent</option>
                              <option value="VG">Very Good</option>
                              <option value="G">Good</option>
                              <option value="P">Poor</option>
                              <option value="VP">Very Poor</option>
                            </>
                          )}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="2"  >
                        <Form.Label>CLIENT NAME<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Control
                          type="text"
                          id="clientNameInput"
                          onBlur={(e) => setVehicleClient(e.target.value)}
                        />
                      </Form.Group>
                      {isGreyVehicle === true ? (
                        <>
                          <Form.Group as={Col} md="2"  >
                            <Form.Label>TRANSMISSION<span style={{ color: "red" }}> *</span></Form.Label>
                            <Form.Select aria-label="Default select example"
                              onChange={(e) => setVehicleTransmission(e.target.value)}>
                              <option></option>
                              <option value="Maunual">Manual</option>
                              <option value="Automatic">Automatic</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md="2"  >
                            <Form.Label>DRIVE<span style={{ color: "red" }}>*</span></Form.Label>
                            <Form.Select aria-label="Default select example"
                              onChange={(e) => setVehicleDrive(e.target.value)}>
                              <option></option>
                              <option value="4x4">4x4</option>
                              <option value="2x4">2x4</option>
                              <option value="AWD">AWD</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md="2"  >
                            <Form.Label>BODY STYLE<span style={{ color: "red" }}> *</span></Form.Label>
                            <Form.Select aria-label="Default select example"
                              onChange={(e) => setVehicleBodyStyle(e.target.value)}>
                              <option></option>
                              <option value="Bus">Bus</option>
                              <option value="Cabriolet">Cabriolet</option>
                              <option value="Coupe">Coupe</option>
                              <option value="Hatchback">Hatchback</option>
                              <option value="MPV">MPV</option>
                              <option value="SUV">SUV</option>
                              <option value="Sedan">Sedan</option>
                              <option value="SingleCab">Single Cab</option>
                              <option value="DoubleCab">Double Cab</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group as={Col} md="2"  >
                            <Form.Label>EXTRAS<span style={{ color: "red" }}> *</span></Form.Label>
                            <ReactSelect
                              options={extraOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option
                              }}
                              allowSelectAll={false}
                              onChange={handleChange}
                              value={optionSelected}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="2">
                            <Form.Label>
                              VIN <span style={{ color: "red" }}> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="vinInput"
                              onBlur={(e) => checkVehicleVin((e.target.value).trim())}
                            />
                          </Form.Group>
                        </>) : null}

                    </Row>
                  </>
                  {isFullSearch === true && makeModelList ? (
                    <Row>
                      <Col></Col>
                      <Col>
                        <br />
                        <br />
                        <span style={{ color: "red" }}>PLEASE SELECT MAKE AND MODEL TO DO A DEEPER SEARCH : </span>
                      </Col>
                      <Form.Group as={Col} md="2" >
                        <Form.Label>MAKE<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => setModelListFilter(e.target.value)}>
                          <option></option>
                          {makeModelList.map(mm => (
                            <option value={mm.make.id}>{mm.make.name}</option>
                          )
                          )}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="2" >
                        <Form.Label>MODEL<span style={{ color: "red" }}> *</span></Form.Label>
                        <Form.Select aria-label="Default select example"
                          onChange={(e) => modelSelected(modelList[e.target.value])}>
                          <option></option>
                          {modelList.map((model, index) => (
                            <option value={index}>{model.name}</option>
                          )
                          )}
                          <option></option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                  ) : null}
                  {vehicleTrim !== "" ? (
                    <Row>
                      <Col></Col>
                      <Col></Col>
                      <Col></Col>
                      <Col>
                        <br />
                        {vehicleTrim === "OTHER" ? (
                          <Button variant="flat-pink" onClick={() => setVehicleTrimButtonPressed(otherVehicleTrim)}>SUBMIT OTHER TRIM</Button>
                        ) : null}
                      </Col>
                      <Form.Group as={Col} md="2" >
                        <Form.Label>SELECTED TRIM</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={vehicleTrim}
                          onChange={(e) => setOtherVehicleTrim(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  ) : null}
                  {isEvalButton === false && trimLevels.length !== 0 && vehicleTrim === "" ? (
                    <>
                      <br />
                      <Row>
                        <hr
                          style={{
                            color: "black",
                            backgroundColor: "black",
                            height: 5
                          }}
                        />
                      </Row>
                      <Col>
                        <br />
                        <span style={{ color: "red" }}> SELECT A TRIM LEVEL :</span>
                      </Col>
                      <Container>
                        <Row>
                          <Col>{trimLevels[0] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[0])}>{trimLevels[0]}</Button>) : null}</Col>
                          <Col>{trimLevels[1] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[1])}>{trimLevels[1]}</Button>) : null}</Col>
                          <Col>{trimLevels[2] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[2])}>{trimLevels[2]}</Button>) : null}</Col>
                          <Col>{trimLevels[3] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[3])}>{trimLevels[3]}</Button>) : null}</Col>
                          <Col>{trimLevels[4] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[4])}>{trimLevels[4]}</Button>) : null}</Col>
                          <Col>{trimLevels[5] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[5])}>{trimLevels[5]}</Button>) : null}</Col>
                        </Row>
                        <Row>
                          <Col>{trimLevels[6] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[6])}>{trimLevels[6]}</Button>) : null}</Col>
                          <Col>{trimLevels[7] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[7])}>{trimLevels[7]}</Button>) : null}</Col>
                          <Col>{trimLevels[8] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[8])}>{trimLevels[8]}</Button>) : null}</Col>
                          <Col>{trimLevels[9] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[9])}>{trimLevels[9]}</Button>) : null}</Col>
                          <Col>{trimLevels[10] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[10])}>{trimLevels[10]}</Button>) : null}</Col>
                          <Col>{trimLevels[11] ? (<Button variant="flat" onClick={() => setVehicleTrimButtonPressed(trimLevels[11])}>{trimLevels[11]}</Button>) : null}</Col>
                        </Row>
                        <Row>
                          <Col><Button variant="flat" onClick={() => setVehicleTrimButtonPressed("OTHER")}>OTHER</Button></Col>
                        </Row>
                      </Container>
                    </>
                  ) : null}
                  <Row>
                    <Col>
                      <br />
                      <Button variant="flat" onClick={() => clearForm()} hidden={isEvalButton}>
                        CLEAR INPUTS
                      </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                      <br />
                      <Button hidden={true} variant="flat" type="submit" id='formSubmitButton'>
                        EVALUATE HIDDEN BUTTON
                      </Button>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                      <br />
                      {vehicleTrim !== "OTHER" ? (
                        <Button variant="flat" onClick={() => confirmSearch()} disabled={isEvalButton}>
                          {isEvalButton ? (
                            <>
                              {evaluateButtonText + " "}
                              <Spinner animation="border" role="status" size="sm">
                                <span className=""></span>
                              </Spinner>
                            </>
                          ) : (
                            <>
                              EVALUATE
                            </>
                          )}
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row >
          <Col style={{ display: 'flex', justifyContent: 'center' }} >
            <Col md="10">
              <Card>
                <Card.Header style={{ backgroundColor: 'grey' }}>
                  PREVIOUS EVALUATIONS
                </Card.Header>
                <Card.Body>
                  {!isLoading ? (
                    <>
                      <Grid
                        style={{
                          height: "500px",
                        }}
                        data={filterBy(searches, filter)}
                        filterable={true}
                        editField="inEdit"
                        filter={filter}
                        expandField="expanded"
                        onExpandChange={expandChange}
                        onFilterChange={e => setFilter(e.filter)}
                      >
                        {/* <GridColumn filter='false' field="found" title="FOUND" width="100px" /> */}
                        <GridColumn field="vehicleVin" title="VIN NUMBER" width="180px" />
                        <GridColumn field="vehicleReg" title="REG NUMBER" width="180px" />
                        <GridColumn field="vehicleMake" title="MAKE" width="130px" />
                        <GridColumn field="vehicleModel" title="MODEL" />
                        <GridColumn field="vehicleClient" title="CLIENT" />
                        <GridColumn field="searchDate" title="DATE" />
                        {/* <GridColumn field="userId" title="USER" /> */}
                        <GridColumn cell={CommandCell} width="200px" />
                      </Grid>
                    </>
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Col>
        </Row>
      </>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{activeSearch.isGrey !== undefined && activeSearch.isGrey !== null && activeSearch.isGrey === true ? "Valuation Details" : "Valuation Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeSearch.isGrey !== undefined && activeSearch.isGrey !== null && activeSearch.isGrey === true ? (
            <>
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* <Tab eventKey="home" title="Purchase at Source">
                  <Row>
                    <Col>
                      <b>Vehicle Vin :</b>
                    </Col>
                    <Col>
                      {activeSearch.vehicleVin}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Low Value:</b>
                    </Col>
                    <Col>
                      {greyImport.valuationDetails.lowValue} USD
                    </Col>
                    <Col>
                      {(greyImport.valuationDetails.lowValue * window.sessionStorage.getItem("UsdToBwp")).toFixed()} BWP
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>High Value:</b>
                    </Col>
                    <Col>
                      {greyImport.valuationDetails.highValue} USD
                    </Col>
                    <Col>
                      {(greyImport.valuationDetails.highValue * window.sessionStorage.getItem("UsdToBwp")).toFixed()} BWP
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Mean Value:</b>
                    </Col>
                    <Col>
                      {greyImport.valuationDetails.meanValue} USD
                    </Col>
                    <Col>
                      {(greyImport.valuationDetails.meanValue * window.sessionStorage.getItem("UsdToBwp")).toFixed()} BWP
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Median Value:</b>
                    </Col>
                    <Col>
                      {greyImport.valuationDetails.medianValue} USD
                    </Col>
                    <Col>
                      {(greyImport.valuationDetails.medianValue * window.sessionStorage.getItem("UsdToBwp")).toFixed()} BWP
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Recommended:</b>
                    </Col>
                    <Col>
                      {((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3).toFixed()} USD
                    </Col>
                    <Col>
                      {(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3) * window.sessionStorage.getItem("UsdToBwp")).toFixed()} BWP
                    </Col>
                  </Row>
                </Tab> */}
                <Tab eventKey="profile" title="Landed Retail">
                  <Table striped>
                    {/* <thead>
                      <tr>
                        <th>Valuation Details (INCL VAT) :</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead> */}
                    <tbody>
                      <tr>
                        <td></td>
                        {/* <td><b>RETAIL PRICE AT SOURCE</b></td> */}
                        {/* <td><b>LOCAL LANDED COST</b></td> */}
                        <td><b>LOCAL PRIVATE PRICE (INCL VAT)</b></td>
                        <td><b>LOCAL DEALER PRICE (INCL VAT)</b></td>
                      </tr>
                      <tr>
                        <td><b>Recomended Value</b></td>
                        <td>{(Number(greyImport.valuationDetails.privateValue ? greyImport.valuationDetails.privateValue : greyImport.valuationDetails.lowValue) + (greyImport.selectedExtras.length * 5000))} BWP</td>
                        <td>{(Number(greyImport.valuationDetails.dealerValue ? greyImport.valuationDetails.dealerValue : greyImport.valuationDetails.medianValue) + (greyImport.selectedExtras.length * 5000))} BWP</td>
                        {/* <td>{(((Number(greyImport.valuationDetails.lowValue) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionDuties) / 100) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td> */}
                        {/* <td>{(((((Number(greyImport.valuationDetails.lowValue) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionDuties) / 100) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(greyImport.valuationDetails.lowValue) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionDuties) / 100) + ((greyImport.valuationDetails.lowValue * companyObject.companyImportDetails.regionVat) / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td> */}
                      </tr>
                      {/* <tr>
                        <td><b>High Value</b></td>
                        <td>{((Number(greyImport.valuationDetails.highValue) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                        <td>{(((Number(greyImport.valuationDetails.highValue) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                        <td>{(((((Number(greyImport.valuationDetails.highValue) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(greyImport.valuationDetails.highValue) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.highValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                      </tr>
                      <tr>
                        <td><b>Mean Value</b></td>
                        <td>{((Number(greyImport.valuationDetails.meanValue) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                        <td>{(((Number(greyImport.valuationDetails.meanValue) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                        <td>{(((((Number(greyImport.valuationDetails.meanValue) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(greyImport.valuationDetails.meanValue) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.meanValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                      </tr>
                      <tr>
                        <td><b>Median Value</b></td>
                        <td>{((Number(greyImport.valuationDetails.medianValue) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                        <td>{(((Number(greyImport.valuationDetails.medianValue) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                        <td>{(((((Number(greyImport.valuationDetails.medianValue) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(greyImport.valuationDetails.medianValue) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionDuties / 100) + (greyImport.valuationDetails.medianValue * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                      </tr>
                      <tr>
                        <td><b>Recomended Value</b></td>
                        <td>{((Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionDuties / 100) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp).toFixed(0)} BWP</td>
                        <td>{(((Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionDuties / 100) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)).toFixed(0)} BWP</td>
                        <td>{(((((Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionDuties / 100) + (Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost)) * 15 / 100) + (((Number(((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3)) + (((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3) * companyObject.companyImportDetails.regionDuties / 100) + (((Number(greyImport.valuationDetails.meanValue) + Number(greyImport.valuationDetails.medianValue) + Number(greyImport.valuationDetails.lowValue)) / 3) * companyObject.companyImportDetails.regionVat / 100)) * currentExchangeRateObject.UsdToBwp) + Number(freightAndLandingCost))).toFixed(0)} BWP</td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </>
          ) : (
            <>
              {activeSearch !== undefined && activeSearch !== null ? (
                <>
                  <Row>
                    <Col>
                      <b>Vehicle Code :</b>
                    </Col>
                    <Col>
                      {activeSearch.vehicleCode}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b> New Price :</b>
                    </Col>
                    <Col>
                      {companyCurrency === 'ZAR' ? (<>R {activeSearch.vehicleNewPrice} </>) : (
                        <>R {(activeSearch.vehicleNewPrice ? activeSearch.vehicleNewPrice : 0).toFixed()} | P {((0.85 * activeSearch.vehicleNewPrice * currentExchangeRate) * 1.14).toFixed()}</>)}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Adj Retail Value :</b>
                    </Col>
                    <Col>
                      {companyCurrency === 'ZAR' ? (<>R {activeSearch.vehicleRetailPrice} </>) : (
                        <>R {(activeSearch.vehicleRetailPrice ? activeSearch.vehicleRetailPrice : 0).toFixed()} | P {((0.85 * activeSearch.vehicleRetailPrice * currentExchangeRate) * 1.14).toFixed()}</>)}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Adj Trade Value :</b>
                    </Col>
                    <Col>
                      {companyCurrency === 'ZAR' ? (<>R {activeSearch.vehicleTradePrice} </>) : (
                        <>R {(activeSearch.vehicleTradePrice ? activeSearch.vehicleTradePrice : 0).toFixed() } | P {((0.85 * activeSearch.vehicleTradePrice * currentExchangeRate) * 1.14).toFixed()}</>)}
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}

        </Modal.Body>
        <Modal.Footer>
          {companyCurrency !== 'ZAR' ? (
            <b>
              EXCHANGE RATE : {currentExchangeRate} |
            </b>) : null}
          <b>
            {currentDateTime}
          </b>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showR}
        onHide={handleCloseR}
        dialogClassName="my-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>VEHICLE REPORT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PdfComponent currentExchangeRateObject={{ 'ZARToBWP': window.sessionStorage.getItem('xrate'), 'UsdToZar': window.sessionStorage.getItem('UsdToZar'), 'UsdToBwp': window.sessionStorage.getItem('UsdToBwp') }} greyImportObject={greyImport} selectedData={activeSearch} transResultsFound={transResultObject[0]} companyObject={companyObject} currentExchangeRate={currentExchangeRate} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseR}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >

  );
};

export default Search;